$cdn: 'https://cdn.millions.co';
@import "styles2/scss/_base";

.root {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  &.theme-white {
    color: $black;

    .subMenu {
      background: $white;
      border: $border-light-grey;
    }

    .active {
      background-color: $cultured;
    }

    .link,
    .childMenuLink,
    .parentMenuWrapper {
      &:hover,
      &:focus {
        background-color: $cultured;
      }
    }

    .inverted {
      color: $white;
      background-color: $black;

      &.active {
        color: $hover-color;
        background-color: $black;
      }

      &:hover,
      &:focus {
        color: $hover-color;
        background-color: $black;
      }
    }
  }

  &.theme-black {
    color: $white;

    .subMenu {
      background: $black;
      border: $border-transparent;
    }

    .active {
      color: $hover-color;
    }

    .link,
    .childMenuLink > a,
    .parentMenuWrapper {
      &:hover,
      &:focus {
        color: $hover-color;
      }
    }

    .inverted {
      background: $white;
      color: $black;

      &.active {
        color: $black;
        background-color: $cultured;
      }

      &:hover,
      &:focus {
        color: $hover-color;
        background-color: $black;
      }
    }
  }
}

.menuWrapper {
  height: 100%;
  position: relative;

  &:hover {
    cursor: pointer;

    .subMenu {
      display: block;
    }

    .dropdownIcon {
      transform: rotate(180deg);
    }
  }
}

.link,
.parentMenuWrapper {
  height: 100%;
  padding: 0 12px;

  @include flexCenter;
}

.link,
.parentMenuLink,
.childMenuLink {
  outline: none;
  white-space: nowrap;

  @include text-xs-medium-uppercase;
}

.childMenuLink a {
  display: block;
  padding: 8px 16px;
}

.dropdownIcon {
  margin-left: 6px;
  width: 10px;
}

.subMenu {
  padding: 8px 0;
  position: absolute;
  top: 40px;
  display: none;
  width: 276px;
  border-top: none;

  &::before {
    position: absolute;
    content: "";
    left: 0;
    top: -12px;
    width: 100%;
    height: 12px;
  }
}
