$cdn: 'https://cdn.millions.co';
@import "styles2/scss/base";

.root {
  width: 100%;
  display: flex;
  flex-direction: column;

  .input,
  div[data-slate-editor="true"] {
    border: 1px solid;
    outline: none;
    background-color: transparent;
    width: 100%;
  }

  div[data-slate-editor="true"] {
    min-height: 120px;
  }

  &.theme {
    &-white {
      &.disabled {
        .input,
        div[data-slate-editor="true"] {
          background: $cultured;
          border-color: $light-gray;
          color: $dove-gray;
        }
      }

      .label {
        margin-bottom: 8px;
        color: $dove-gray;
      }

      .input,
      div[data-slate-editor="true"] {
        color: $black;
        border-color: $light-gray;
        background: $white;

        &:focus {
          border-color: $gainsboro;
        }

        &::placeholder {
          color: $dove-gray;
          -webkit-text-fill-color: $dove-gray;
        }
      }

      .actionIcon {
        color: $black;
      }

      &.error {
        .input,
        div[data-slate-editor="true"] {
          border: 1px solid $maximum-red;
        }
      }

      .noticeLabel {
        color: $dove-gray;
      }
    }

    &-black {
      .entry {
        border: 1px solid $god-grey;
        background-color: $god-grey;

        &:focus-within {
          border-color: $mine-shaft;
        }
      }

      &.error {
        .entry {
          border: 1px solid $maximum-red;
        }
      }

      .label {
        margin-bottom: 7px;
        color: $gainsboro;
      }

      &.disabled {
        .entry {
          background: $chinese-black;
          border-color: $chinese-black;
        }

        .input,
        div[data-slate-editor="true"],
        .label,
        .actionIcon {
          color: rgba(255, 255, 255, 0.4);
        }
      }

      .input,
      div[data-slate-editor="true"] {
        padding: 0;
        color: $white;
        border-color: $god-grey;

        &::placeholder {
          color: rgba(255, 255, 255, 0.4);
          -webkit-text-fill-color: rgba(255, 255, 255, 0.4);
        }
      }

      .actionIcon {
        color: $white;
      }

      .noticeLabel {
        color: $white;
      }
    }
  }

  &.size {
    &-default {
      &.theme {
        &-white {
          .input,
          div[data-slate-editor="true"] {
            padding: 17px 24px;
          }

          &.withAction {
            .input,
            div[data-slate-editor="true"] {
              padding-right: 60px;
            }
          }
        }

        &-black {
          .entry {
            padding: 10px 8px;
          }

          &.unlabeled {
            .entry {
              padding: 17px 24px;
            }

            .input,
            div[data-slate-editor="true"] {
              border: none;
            }
          }

          &.withAction {
            .entry {
              padding-right: 60px;
            }
          }
        }
      }

      .input,
      div[data-slate-editor="true"] {
        @include text-lg-medium;
      }
    }

    &-small {
      &.theme {
        &-white {
          .input {
            padding: 10px 8px 11px;
          }

          &.withAction {
            .input {
              padding-right: 44px;
            }
          }
        }

        &-black {
          .entry {
            padding: 10px 8px;
          }

          &.unlabeled {
            .entry {
              padding: 0;
            }

            .input {
              border: none;
              padding: 10px 8px 11px;
            }

            &.withAction {
              .input {
                padding-right: 44px;
              }
            }
          }
        }
      }

      &.withAction {
        .actionButton {
          right: 8px;
        }
      }

      .input {
        font-family: "GT America";
        font-weight: $regular_font_weight;
        letter-spacing: 0.02em;

        @include font-size(14px);
        @include line-height(17px);
      }
    }
  }

  &.multiline {
    .input {
      resize: none;
    }
  }

  &.disabled {
    &,
    * {
      cursor: no-drop;
    }
  }
}

.input {
  border-radius: 0;
  margin: 0;
  appearance: none;
}

.label {
  display: block;

  @include text-xs-medium-uppercase;
}

.textFieldWrapper,
.entry {
  position: relative;
  display: flex;
  flex-direction: column;
}

.errorLabel {
  display: block;
  margin-top: 8px;
  color: $maximum-red;

  @include text-sm-regular;
}

.actionButton {
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translate(0, -50%);
  background: transparent;
  padding: 0;
  margin: 0;
  border: none;
  cursor: pointer;

  &:not(:disabled):hover {
    opacity: 0.8;
  }

  @include flexCenter;
}

.actionIcon {
  font-size: 24px;
}

.noticeLabel {
  display: block;
  margin-top: 8px;

  @include text-sm-regular;
}

.endAdornment {
  display: flex;
  align-items: center;
  margin: auto 4px;
}


.passwordToggle {
  @extend %button-reset;

  margin-right: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  padding: 8px;
  color: $dusty-grey;

  .passwordToggleIcon {
    color: inherit;
    font-size: inherit;
  }
}
