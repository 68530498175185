$cdn: 'https://cdn.millions.co';
@import "styles/scss/_base";

div.overlay {
  padding: 0;

  @media screen and (min-width: $breakpoint_m) {
    padding: 1.2rem;
  }
}

div.modal {
  margin: 0;
  padding: 48px 34px;
  min-height: 100vh;
  width: 100%;
  border-radius: 0;

  @media screen and (min-width: $breakpoint_m) {
    min-height: unset;
    border-radius: 8px;
  }

  &.size {
    &-small {
      max-width: unset;
      min-height: 100vh;

      @media screen and (min-width: 615px) {
        max-width: 452px;
        min-height: unset;
      }
    }

    &-medium {
      max-width: 585px;
    }

    &-large {
      max-width: 884px;
    }

    &-extra-large {
      max-width: 1116px;
    }
  }

  .closeButton {
    top: 20px;
    right: 20px;
    padding: 0;
    border-radius: 50%;
    outline: none;

    &:hover,
    &:focus {
      background: transparent;

      .closeIcon {
        fill: $harvest-gold;
        background-color: transparent;
        transform: rotate(90deg);
      }
    }
  }

  .closeIcon {
    width: 25px;
    height: 25px;
    fill: $black;
  }
}

.title {
  margin-bottom: 40px;
  font-weight: $bold_font_weight;
  text-align: center;
  letter-spacing: -0.8px;
  color: $black;

  @include font_size(36px);
  @include line_height(46px);

  &.withSubTitle {
    margin-bottom: 8px;
  }
}

.subTitle {
  margin-bottom: 32px;
  font-weight: $regular_font_weight;
  text-align: center;
  letter-spacing: -0.2px;
  color: $dove-gray;

  @include font_size(16px);
  @include line_height(22px);
}
