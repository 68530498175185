$cdn: 'https://cdn.millions.co';
@import "styles/scss/base";

$switch-width: 48px;
$switch-height: 26px;
$switch-circle-indent: 2px;

.switchWrapper {
  margin: 0;
  width: 100%;
}

.switchCheckbox {
  margin: 0;
  padding: 0;
  width: 0;
  height: 0;
  visibility: hidden;

  &:checked + .switchLabel {
    &:before {
      background: $harvest-gold;
    }

    &:after {
      left: $switch-width - $switch-circle-indent;
      background: $white;
      transform: translateX(-100%);
    }
  }

  &[disabled] + .switchLabel {
    &,
    &:hover,
    &:focus,
    &:active {
      &,
      &:before,
      &:after {
        user-select: none;
        pointer-events: none;
        cursor: not-allowed;
        filter: grayscale(0.75);
      }
    }
  }
}

.switchLabel {
  position: relative;
  display: inline-block;
  padding-left: $switch-width + 10px;
  height: $switch-height;
  color: $black-magic;
  letter-spacing: -0.2px;
  font-weight: $semi_bold_font_weight;
  cursor: pointer;

  @include font_size(13px);
  @include line_height($switch-height);

  &:before {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: $switch-width;
    height: $switch-height;
    background: $alto-light;
    border-radius: 18px;
    cursor: pointer;
  }

  &:after {
    content: "";
    position: absolute;
    top: $switch-circle-indent;
    left: $switch-circle-indent;
    width: $switch-height - $switch-circle-indent*2;
    height: $switch-height - $switch-circle-indent*2;
    background: $mountain-mist;
    border-radius: 100%;
    transition: 0.15s;
  }

  &:active {
    &:before {
      filter: grayscale(0.5);
    }

    &:after {
      width: $switch-height * 1.125;
      filter: grayscale(0.5);
    }
  }
}
