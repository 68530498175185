$cdn: 'https://cdn.millions.co';
@import "styles2/scss/base";

.root {
  width: 100%;
  padding: 0 $mobile_gutter;

  @media screen and (min-width: $breakpoint_l) {
    padding: 0 $desktop_gutter;
  }

  @media screen and (min-width: $breakpoint_xxl) {
    max-width: $max-resolution-size;
    margin: 0 auto;
  }
}
