$cdn: 'https://cdn.millions.co';
@import "styles2/scss/_base";

.root {
  cursor: pointer;
  display: flex;
  align-items: center;

  @extend %button-reset;

  &:hover,
  &:focus {
    .cartIcon,
    .cartCounter {
      color: $hover-color;
    }
  }

  &.theme-white {
    .cartIcon {
      color: $black;
    }

    .cartCounter {
      color: $white;
      background-color: $black;
    }
  }

  &.theme-black {
    .cartIcon {
      color: $white;
    }

    .cartCounter {
      color: $black;
      background-color: $white;
    }
  }
}

.cartIcon {
  width: 18px;
  height: 20px;
  margin-right: 6px;
}

.cartCounter {
  min-width: 20px;
  min-height: 20px;
  font-weight: $regular_font_weight;
  border-radius: 50%;
  flex-shrink: 0;

  @include flexCenter;
  @include font_size(10px);
  @include line_height(12px);
}
