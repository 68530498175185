$cdn: 'https://cdn.millions.co';
@import "styles2/scss/_base";

.root {
  position: relative;
  top: 0;
  width: 100%;
  height: $mobile_header_height;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 6;
  transition: background-color 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);

  @media screen and (min-width: $breakpoint_l) {
    display: none;
  }

  &.transparent {
    background-color: transparentize($white, 0.5);
  }

  &.fixed {
    position: fixed;
  }

  &.theme-white {
    color: $black;
    background-color: $white;
    border-bottom: $border-light-grey;

    .logoWrapper {
      border-right: $border-light-grey;
    }

    .menuToggle {
      span {
        background: $black;
      }
    }

    .nav {
      background-color: $white;
    }

    .authButton:first-child {
      border-top: $border-light-grey;
      border-bottom: $border-light-grey;
    }

    .logoutButton:first-of-type {
      border-top: $border-light-grey;
      border-bottom: $border-light-grey;
    }

    .link {
      border-bottom: $border-light-grey;
    }

    .collapseWrapper {
      &:first-of-type {
        border-bottom: $border-light-grey;
      }

      &.collapseProfileWrapper {
        border-top: $border-light-grey;
      }
    }
  }

  &.theme-black {
    color: $white;

    background-color: $black;
    border-bottom: $border-transparent;

    .logoWrapper {
      border-right: $border-transparent;
    }

    .menuToggle {
      span {
        background: $white;
      }
    }

    .nav {
      background-color: $black;
    }

    .authButton:first-child {
      border-top: $border-transparent;
      border-bottom: $border-transparent;
    }

    .logoutButton:first-of-type {
      border-top: $border-transparent;
      border-bottom: $border-transparent;
    }

    .link {
      border-bottom: $border-transparent;
    }

    .collapseWrapper {
      background-color: $black;

      & div > button > i {
        color: $white;
      }

      &:first-of-type {
        border-bottom: $border-transparent;
      }

      &.collapseProfileWrapper {
        border-top: $border-transparent;
      }
    }
  }
}

.logoWrapper {
  display: flex;
  align-items: center;
  height: 100%;
  padding: $mobile_gutter;

  .logo {
    width: 29px;
    height: auto;
    cursor: pointer;
  }
}

.menuWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $mobile_gutter;
}

.menuToggle {
  -webkit-user-select: none;
  user-select: none;
  background: transparent;
  border: none;
  padding: 0;
  outline: none;

  &:hover {
    color: $hover-color;
  }

  span {
    display: block;
    width: 19px;
    height: 2px;
    margin-bottom: 5px;
    position: relative;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 4px 0;
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
      background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;

    &:nth-of-type(3) {
      margin-bottom: 0;
    }
  }

  &.open {
    span {
      &:nth-of-type(1) {
        opacity: 1;
        transform: rotate(45deg) translate(2px, 3px);
      }

      &:nth-of-type(2) {
        opacity: 0;
      }

      &:nth-of-type(3) {
        opacity: 1;
        transform: rotate(-45deg);
        transform-origin: 0 100%;
      }
    }
  }
}

.nav {
  position: absolute;
  overflow: auto;
  top: $mobile_header_height;
  height: calc(100vh - #{$mobile_header_height});
  width: 100%;
  flex-direction: column;
  padding-bottom: 24px;
  list-style-type: none;
  display: none;

  &.open {
    display: flex;
  }
}

.subMenu {
  padding: $mobile_gutter 0 0;
}

.link {
  padding: 24px $mobile_gutter;
  display: block;
}

.link,
.parentMenuLink {
  @include text-4xl;

  &.active {
    color: $hover-color;
  }

  @at-root button#{&} {
    @extend %button-reset;
  }
}

.childMenuLink {
  display: block;
  border: none;
  background-color: transparent;
  padding: 0;
  color: $black;

  @include text-xs-medium-uppercase;

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  &.active {
    color: $hover-color;
  }
}

.collapseWrapper {
  overflow: visible;
  border: none;
  border-radius: 0;
  padding: 24px $mobile_gutter;
  display: block;
}

.authButtonWrapper {
  width: 100%;
  margin-bottom: 26px;

  .authButton {
    width: 50%;
  }
}

.logoutButton:first-of-type {
  margin-bottom: 26px;
}

.socialIconWrapper {
  padding-left: $mobile_gutter;
  margin-bottom: 52px;
}

.externalLinkWrapper {
  padding-left: $mobile_gutter;
  display: flex;

  .externalLink {
    @include text-xs-regular;

    &:first-child {
      margin-right: 24px;
    }
  }
}

.inverted {
  background: $black;
  color: $white;
}
