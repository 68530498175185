$cdn: 'https://cdn.millions.co';
@import "styles2/scss/_base";

.root {
  background-color: $black;
  justify-content: space-around;
  align-items: center;
  display: flex;
  width: 6rem;
  margin-right: 1rem;
  color: $white;
  outline: none;
  white-space: nowrap;
  font-family: "GT America", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  @include font_size(12px);
  border: none;

  &:hover {
    cursor: pointer;
    color: $harvest-gold;
  }
}
.icon {
  font-size: 0.8rem;
  color: $harvest-gold;
}
