$cdn: 'https://cdn.millions.co';
@import "styles2/scss/_base";

.root {
  z-index: 2;

  @include flexCenter;

  &.theme-white {
    color: $black;

    .menu {
      background: $white;
      border: $border-light-grey;
    }

    .link,
    .userNameBlock,
    .logoutButton {
      &.active,
      &:hover,
      &:focus {
        background-color: $cultured;
      }
    }
  }

  &.theme-black {
    color: $white;

    .menu {
      background: $black;
      border: $border-transparent;
    }

    .link > a,
    .userNameBlock {
      &.active,
      &:hover,
      &:focus {
        color: $hover-color;
      }
    }
  }
}

.userNameBlock {
  height: 100%;
  padding: 0 12px;

  @include flexCenter;
}

.userName {
  max-width: 120px;
  outline: none;
  white-space: nowrap;
  overflow: hidden;

  @include text-xs-medium-uppercase;
  @include inline_overflow_ellipsis;
}

.dropdownIcon {
  margin-left: 6px;
  width: 10px;
}

.menuWrapper {
  position: relative;
  height: 100%;

  @include flexCenter;

  @media screen and (min-width: $breakpoint_xl) {
    margin-left: 18px;
  }

  &:hover,
  &:focus,
  &:focus-within {
    cursor: pointer;

    .menu {
      display: block;
    }

    .dropdownIcon {
      transform: rotate(180deg);
    }
  }
}

.menu {
  position: absolute;
  right: 0;
  top: 39px;
  display: none;
  padding: 8px 0;
  width: 150px;

  &::before {
    position: absolute;
    content: "";
    left: 0;
    top: -12px;
    width: 100%;
    height: 30px;
  }
}

.link a,
.logoutButton {
  width: 100%;
  display: block;
  padding: 8px 16px;

  @include text-xs-medium-uppercase;
}

.logoutButton {
  color: $maximum-red;
  text-align: left;

  @extend %button-reset;
}
