$cdn: 'https://cdn.millions.co';
@import "styles2/scss/_base";

.root {
  position: relative;
  top: 0;
  width: 100%;
  background-color: $white;
  z-index: 6;
  display: none;

  @media screen and (min-width: $breakpoint_l) {
    display: block;
  }

  &.transparent {
    background-color: transparentize($dark, 0.5);
  }

  &.fixed {
    position: fixed;
  }

  &.theme-white {
    background-color: $white;
    border-bottom: $border-light-grey;

    .logoWrapper {
      border-right: $border-light-grey;
    }

    .navAuthWrapper {
      border-bottom: $border-light-grey;
    }

    .authButtons {
      border-left: $border-light-grey;
    }
  }

  &.theme-black {
    background-color: $black;
    border-bottom: $border-transparent;

    .logoWrapper {
      border-right: $border-transparent;
    }

    .navAuthWrapper {
      border-bottom: $border-transparent;
    }

    .authButtons {
      border-left: $border-transparent;
    }

    .navWrapper {
      @media screen and (min-width: $breakpoint_xxl) {
        border-right: $border-transparent;
      }
    }
  }
}

.resolutionWrapper {
  align-items: center;
  justify-content: center;

  @media screen and (min-width: $breakpoint_l) {
    display: flex;
    height: $desktop_header_height;
    padding: 0 0 0 $desktop_gutter;
  }
}

.wrapper {
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.logoWrapper {
  height: 100%;
  padding: 0 32px 0 0;
  display: flex;
  align-items: center;
  position: relative;

  .logo {
    width: 162px;
    height: auto;
    cursor: pointer;
  }
}

.navWrapper {
  width: 100%;
  height: 100%;

  @media screen and (min-width: $breakpoint_xxl) {
    border-right: $border-light-grey;
  }

  .navAuthWrapper {
    height: 50%;
    padding-right: $desktop_gutter;
    display: flex;
    justify-content: flex-end;
  }

  .navMenuWrapper {
    height: 50%;
    padding: 0 $desktop_gutter 0 0;
    display: flex;
    justify-content: space-between;
  }
  
  .navMenuRightButtons {
    display: flex;
  }
}
